/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidationResult
 */
export interface ValidationResult {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationResult
     */
    readonly memberNames?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationResult
     */
    errorMessage?: string | null;
}

export function ValidationResultFromJSON(json: any): ValidationResult {
    return ValidationResultFromJSONTyped(json, false);
}

export function ValidationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberNames': !exists(json, 'memberNames') ? undefined : json['memberNames'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}

export function ValidationResultToJSON(value?: ValidationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorMessage': value.errorMessage,
    };
}


