/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ELAttachmentTyp {
    Unknown = 'Unknown',
    Signature = 'Signature',
    Bild = 'Bild'
}

export function ELAttachmentTypFromJSON(json: any): ELAttachmentTyp {
    return ELAttachmentTypFromJSONTyped(json, false);
}

export function ELAttachmentTypFromJSONTyped(json: any, ignoreDiscriminator: boolean): ELAttachmentTyp {
    return json as ELAttachmentTyp;
}

export function ELAttachmentTypToJSON(value?: ELAttachmentTyp | null): any {
    return value as any;
}

