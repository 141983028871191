/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjektKontierungModified,
    ProjektKontierungModifiedFromJSON,
    ProjektKontierungModifiedFromJSONTyped,
    ProjektKontierungModifiedToJSON,
    TaetigkeitenModel,
    TaetigkeitenModelFromJSON,
    TaetigkeitenModelFromJSONTyped,
    TaetigkeitenModelToJSON,
} from './';

/**
 * Model Kontierungen
 * @export
 * @interface KontierungenModel
 */
export interface KontierungenModel {
    /**
     * Projektkontierung ID
     * @type {string}
     * @memberof KontierungenModel
     */
    id?: string;
    /**
     * Mitarbeiter ID
     * @type {string}
     * @memberof KontierungenModel
     */
    mitarbeiterID?: string;
    /**
     * Projekt ID
     * @type {string}
     * @memberof KontierungenModel
     */
    projektID?: string;
    /**
     * Taetigkeit ID
     * @type {string}
     * @memberof KontierungenModel
     */
    taetigkeitID?: string | null;
    /**
     * Name der Tätigkeiten
     * @type {string}
     * @memberof KontierungenModel
     */
    taetigkeitName?: string | null;
    /**
     * Beschreibung der Tätigkeiten
     * @type {string}
     * @memberof KontierungenModel
     */
    taetigkeitBeschreibung?: string | null;
    /**
     * Projektname
     * @type {string}
     * @memberof KontierungenModel
     */
    projektname?: string | null;
    /**
     * Beschreibung des Projekt
     * @type {string}
     * @memberof KontierungenModel
     */
    projektBeschreibung?: string | null;
    /**
     * Minuten die ein Mitarbeiter zu einem Projekt kontiert.
     * @type {number}
     * @memberof KontierungenModel
     */
    anzahlMinuten?: number;
    /**
     * Beschreibung der Tätigkeiten eines Mitarbeiters
     * @type {string}
     * @memberof KontierungenModel
     */
    anmerkung?: string | null;
    /**
     * Tag für den Kontiert werden soll.
     * @type {Date}
     * @memberof KontierungenModel
     */
    datum?: Date;
    /**
     * Anmerkung Pflichtfeld?
     * @type {boolean}
     * @memberof KontierungenModel
     */
    anmerkungErforderlich?: boolean;
    /**
     * Favorit?
     * @type {boolean}
     * @memberof KontierungenModel
     */
    favorit?: boolean;
    /**
     * Für nicht editierbare Kontierungen (zb.: Berufsschule)
     * @type {boolean}
     * @memberof KontierungenModel
     */
    readOnly?: boolean;
    /**
     * Iconfile
     * @type {string}
     * @memberof KontierungenModel
     */
    file?: string | null;
    /**
     * File content type
     * @type {string}
     * @memberof KontierungenModel
     */
    contentType?: string | null;
    /**
     * Ausgewählte Tätigkeit ist Anmerkung Pflichtfeld?
     * @type {boolean}
     * @memberof KontierungenModel
     */
    taetigkeitAnmerkungErforderlich?: boolean;
    /**
     * Ausgewählte Taetigkeit ist Favorit?
     * @type {boolean}
     * @memberof KontierungenModel
     */
    taetigkeitFavorit?: boolean;
    /**
     * Liste der verfügbaren Tätigkeiten des Projekts
     * @type {Array<TaetigkeitenModel>}
     * @memberof KontierungenModel
     */
    projektTaetigkeiten?: Array<TaetigkeitenModel> | null;
    /**
     * Liste der Kontierungen für Detailansicht
     * @type {Array<ProjektKontierungModified>}
     * @memberof KontierungenModel
     */
    detailKontierungen?: Array<ProjektKontierungModified> | null;
}

export function KontierungenModelFromJSON(json: any): KontierungenModel {
    return KontierungenModelFromJSONTyped(json, false);
}

export function KontierungenModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): KontierungenModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mitarbeiterID': !exists(json, 'mitarbeiterID') ? undefined : json['mitarbeiterID'],
        'projektID': !exists(json, 'projektID') ? undefined : json['projektID'],
        'taetigkeitID': !exists(json, 'taetigkeitID') ? undefined : json['taetigkeitID'],
        'taetigkeitName': !exists(json, 'taetigkeitName') ? undefined : json['taetigkeitName'],
        'taetigkeitBeschreibung': !exists(json, 'taetigkeitBeschreibung') ? undefined : json['taetigkeitBeschreibung'],
        'projektname': !exists(json, 'projektname') ? undefined : json['projektname'],
        'projektBeschreibung': !exists(json, 'projektBeschreibung') ? undefined : json['projektBeschreibung'],
        'anzahlMinuten': !exists(json, 'anzahlMinuten') ? undefined : json['anzahlMinuten'],
        'anmerkung': !exists(json, 'anmerkung') ? undefined : json['anmerkung'],
        'datum': !exists(json, 'datum') ? undefined : (new Date(json['datum'])),
        'anmerkungErforderlich': !exists(json, 'anmerkungErforderlich') ? undefined : json['anmerkungErforderlich'],
        'favorit': !exists(json, 'favorit') ? undefined : json['favorit'],
        'readOnly': !exists(json, 'readOnly') ? undefined : json['readOnly'],
        'file': !exists(json, 'file') ? undefined : json['file'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'taetigkeitAnmerkungErforderlich': !exists(json, 'taetigkeitAnmerkungErforderlich') ? undefined : json['taetigkeitAnmerkungErforderlich'],
        'taetigkeitFavorit': !exists(json, 'taetigkeitFavorit') ? undefined : json['taetigkeitFavorit'],
        'projektTaetigkeiten': !exists(json, 'projektTaetigkeiten') ? undefined : (json['projektTaetigkeiten'] === null ? null : (json['projektTaetigkeiten'] as Array<any>).map(TaetigkeitenModelFromJSON)),
        'detailKontierungen': !exists(json, 'detailKontierungen') ? undefined : (json['detailKontierungen'] === null ? null : (json['detailKontierungen'] as Array<any>).map(ProjektKontierungModifiedFromJSON)),
    };
}

export function KontierungenModelToJSON(value?: KontierungenModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'mitarbeiterID': value.mitarbeiterID,
        'projektID': value.projektID,
        'taetigkeitID': value.taetigkeitID,
        'taetigkeitName': value.taetigkeitName,
        'taetigkeitBeschreibung': value.taetigkeitBeschreibung,
        'projektname': value.projektname,
        'projektBeschreibung': value.projektBeschreibung,
        'anzahlMinuten': value.anzahlMinuten,
        'anmerkung': value.anmerkung,
        'datum': value.datum === undefined ? undefined : (value.datum.toISOString()),
        'anmerkungErforderlich': value.anmerkungErforderlich,
        'favorit': value.favorit,
        'readOnly': value.readOnly,
        'file': value.file,
        'contentType': value.contentType,
        'taetigkeitAnmerkungErforderlich': value.taetigkeitAnmerkungErforderlich,
        'taetigkeitFavorit': value.taetigkeitFavorit,
        'projektTaetigkeiten': value.projektTaetigkeiten === undefined ? undefined : (value.projektTaetigkeiten === null ? null : (value.projektTaetigkeiten as Array<any>).map(TaetigkeitenModelToJSON)),
        'detailKontierungen': value.detailKontierungen === undefined ? undefined : (value.detailKontierungen === null ? null : (value.detailKontierungen as Array<any>).map(ProjektKontierungModifiedToJSON)),
    };
}


