/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ELAttachmentTyp,
    ELAttachmentTypFromJSON,
    ELAttachmentTypFromJSONTyped,
    ELAttachmentTypToJSON,
} from './';

/**
 * Signature Picture
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * Name
     * @type {string}
     * @memberof Attachment
     */
    name?: string | null;
    /**
     * File
     * @type {string}
     * @memberof Attachment
     */
    file?: string | null;
    /**
     * Contenttype
     * @type {string}
     * @memberof Attachment
     */
    contentType?: string | null;
    /**
     * 
     * @type {ELAttachmentTyp}
     * @memberof Attachment
     */
    anhangTyp?: ELAttachmentTyp;
}

export function AttachmentFromJSON(json: any): Attachment {
    return AttachmentFromJSONTyped(json, false);
}

export function AttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'file': !exists(json, 'file') ? undefined : json['file'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'anhangTyp': !exists(json, 'anhangTyp') ? undefined : ELAttachmentTypFromJSON(json['anhangTyp']),
    };
}

export function AttachmentToJSON(value?: Attachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'file': value.file,
        'contentType': value.contentType,
        'anhangTyp': ELAttachmentTypToJSON(value.anhangTyp),
    };
}


