/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ERolle {
    None = 'None',
    Admin = 'Admin',
    Abteilungsleiter = 'Abteilungsleiter',
    Mitarbeiter = 'Mitarbeiter',
    Controlling = 'Controlling',
    Projekterfassung = 'Projekterfassung'
}

export function ERolleFromJSON(json: any): ERolle {
    return ERolleFromJSONTyped(json, false);
}

export function ERolleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ERolle {
    return json as ERolle;
}

export function ERolleToJSON(value?: ERolle | null): any {
    return value as any;
}

