/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EAZTyp,
    EAZTypFromJSON,
    EAZTypFromJSONTyped,
    EAZTypToJSON,
    EUIMode,
    EUIModeFromJSON,
    EUIModeFromJSONTyped,
    EUIModeToJSON,
    SonstigeTypenModel,
    SonstigeTypenModelFromJSON,
    SonstigeTypenModelFromJSONTyped,
    SonstigeTypenModelToJSON,
    StempelModel,
    StempelModelFromJSON,
    StempelModelFromJSONTyped,
    StempelModelToJSON,
    TagStempelInfo,
    TagStempelInfoFromJSON,
    TagStempelInfoFromJSONTyped,
    TagStempelInfoToJSON,
} from './';

/**
 * Info und Stempel eines Arbeitstages
 * @export
 * @interface TagStempel
 */
export interface TagStempel {
    /**
     * 
     * @type {TagStempelInfo}
     * @memberof TagStempel
     */
    info?: TagStempelInfo;
    /**
     * Arbeitstag editierbar?
     * @type {boolean}
     * @memberof TagStempel
     */
    isEditable?: boolean;
    /**
     * Montage aktiviert?
     * @type {boolean}
     * @memberof TagStempel
     */
    montage?: boolean;
    /**
     * 
     * @type {EUIMode}
     * @memberof TagStempel
     */
    uiMode?: EUIMode;
    /**
     * Kontierungen löschen?
     * @type {boolean}
     * @memberof TagStempel
     */
    deleteKontierungen?: boolean;
    /**
     * 
     * @type {EAZTyp}
     * @memberof TagStempel
     */
    showStempel?: EAZTyp;
    /**
     * 
     * @type {StempelModel}
     * @memberof TagStempel
     */
    zeitstempel?: StempelModel;
    /**
     * Liefert eine Auswahl der Stempeloptionen für das Dropwdown
     * @type {Array<SonstigeTypenModel>}
     * @memberof TagStempel
     */
    sonstigeDropdown?: Array<SonstigeTypenModel> | null;
    /**
     * FormID
     * @type {string}
     * @memberof TagStempel
     */
    formID?: string | null;
}

export function TagStempelFromJSON(json: any): TagStempel {
    return TagStempelFromJSONTyped(json, false);
}

export function TagStempelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagStempel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'info': !exists(json, 'info') ? undefined : TagStempelInfoFromJSON(json['info']),
        'isEditable': !exists(json, 'isEditable') ? undefined : json['isEditable'],
        'montage': !exists(json, 'montage') ? undefined : json['montage'],
        'uiMode': !exists(json, 'uiMode') ? undefined : EUIModeFromJSON(json['uiMode']),
        'deleteKontierungen': !exists(json, 'deleteKontierungen') ? undefined : json['deleteKontierungen'],
        'showStempel': !exists(json, 'showStempel') ? undefined : EAZTypFromJSON(json['showStempel']),
        'zeitstempel': !exists(json, 'zeitstempel') ? undefined : StempelModelFromJSON(json['zeitstempel']),
        'sonstigeDropdown': !exists(json, 'sonstigeDropdown') ? undefined : (json['sonstigeDropdown'] === null ? null : (json['sonstigeDropdown'] as Array<any>).map(SonstigeTypenModelFromJSON)),
        'formID': !exists(json, 'formID') ? undefined : json['formID'],
    };
}

export function TagStempelToJSON(value?: TagStempel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'info': TagStempelInfoToJSON(value.info),
        'isEditable': value.isEditable,
        'montage': value.montage,
        'uiMode': EUIModeToJSON(value.uiMode),
        'deleteKontierungen': value.deleteKontierungen,
        'showStempel': EAZTypToJSON(value.showStempel),
        'zeitstempel': StempelModelToJSON(value.zeitstempel),
        'sonstigeDropdown': value.sonstigeDropdown === undefined ? undefined : (value.sonstigeDropdown === null ? null : (value.sonstigeDropdown as Array<any>).map(SonstigeTypenModelToJSON)),
        'formID': value.formID,
    };
}


