/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RessourcenUebersichtModel,
    RessourcenUebersichtModelFromJSON,
    RessourcenUebersichtModelFromJSONTyped,
    RessourcenUebersichtModelToJSON,
} from './';

/**
 * Model: Arbeitstag eines Mitarbeiters
 * @export
 * @interface RessourcenModel
 */
export interface RessourcenModel {
    /**
     * Projekt Namepath
     * @type {string}
     * @memberof RessourcenModel
     */
    projektname?: string | null;
    /**
     * Projekt Adresse
     * @type {string}
     * @memberof RessourcenModel
     */
    projektAdresse?: string | null;
    /**
     * Projekt Telefonnr.
     * @type {string}
     * @memberof RessourcenModel
     */
    projektTelefon?: string | null;
    /**
     * Projekt Ansprechsperson
     * @type {string}
     * @memberof RessourcenModel
     */
    projektKontakt?: string | null;
    /**
     * Projektfarbe
     * @type {string}
     * @memberof RessourcenModel
     */
    farbe?: string | null;
    /**
     * Tage der Arbeitsplanung
     * @type {Array<string>}
     * @memberof RessourcenModel
     */
    tage?: Array<string> | null;
    /**
     * Mitarbeiter ist Anwesend von
     * @type {Date}
     * @memberof RessourcenModel
     */
    vonZeit?: Date | null;
    /**
     * Mitarbeiter ist Anwesend bis
     * @type {Date}
     * @memberof RessourcenModel
     */
    bisZeit?: Date | null;
    /**
     * Liefert für jeden Tag die Projektarbeitszeit
     * @type {Array<RessourcenUebersichtModel>}
     * @memberof RessourcenModel
     */
    einteilung?: Array<RessourcenUebersichtModel> | null;
    /**
     * Gewählter Zeitraum (Mo-So)
     * @type {string}
     * @memberof RessourcenModel
     */
    zeitraum?: string | null;
    /**
     * Kalenderwoche
     * @type {number}
     * @memberof RessourcenModel
     */
    kalenderwoche?: number;
    /**
     * Fehler beim auslesen der Arbeitseinteilung
     * @type {string}
     * @memberof RessourcenModel
     */
    errorMsg?: string | null;
}

export function RessourcenModelFromJSON(json: any): RessourcenModel {
    return RessourcenModelFromJSONTyped(json, false);
}

export function RessourcenModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RessourcenModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projektname': !exists(json, 'projektname') ? undefined : json['projektname'],
        'projektAdresse': !exists(json, 'projektAdresse') ? undefined : json['projektAdresse'],
        'projektTelefon': !exists(json, 'projektTelefon') ? undefined : json['projektTelefon'],
        'projektKontakt': !exists(json, 'projektKontakt') ? undefined : json['projektKontakt'],
        'farbe': !exists(json, 'farbe') ? undefined : json['farbe'],
        'tage': !exists(json, 'tage') ? undefined : json['tage'],
        'vonZeit': !exists(json, 'vonZeit') ? undefined : (json['vonZeit'] === null ? null : new Date(json['vonZeit'])),
        'bisZeit': !exists(json, 'bisZeit') ? undefined : (json['bisZeit'] === null ? null : new Date(json['bisZeit'])),
        'einteilung': !exists(json, 'einteilung') ? undefined : (json['einteilung'] === null ? null : (json['einteilung'] as Array<any>).map(RessourcenUebersichtModelFromJSON)),
        'zeitraum': !exists(json, 'zeitraum') ? undefined : json['zeitraum'],
        'kalenderwoche': !exists(json, 'kalenderwoche') ? undefined : json['kalenderwoche'],
        'errorMsg': !exists(json, 'errorMsg') ? undefined : json['errorMsg'],
    };
}

export function RessourcenModelToJSON(value?: RessourcenModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projektname': value.projektname,
        'projektAdresse': value.projektAdresse,
        'projektTelefon': value.projektTelefon,
        'projektKontakt': value.projektKontakt,
        'farbe': value.farbe,
        'tage': value.tage,
        'vonZeit': value.vonZeit === undefined ? undefined : (value.vonZeit === null ? null : value.vonZeit.toISOString()),
        'bisZeit': value.bisZeit === undefined ? undefined : (value.bisZeit === null ? null : value.bisZeit.toISOString()),
        'einteilung': value.einteilung === undefined ? undefined : (value.einteilung === null ? null : (value.einteilung as Array<any>).map(RessourcenUebersichtModelToJSON)),
        'zeitraum': value.zeitraum,
        'kalenderwoche': value.kalenderwoche,
        'errorMsg': value.errorMsg,
    };
}


