/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * View-Model: Error
 * @export
 * @interface NeuesPasswortModel
 */
export interface NeuesPasswortModel {
    /**
     * Aktuelles Passwort
     * @type {string}
     * @memberof NeuesPasswortModel
     */
    altesPasswort?: string | null;
    /**
     * Neues Passwort
     * @type {string}
     * @memberof NeuesPasswortModel
     */
    neuesPasswort?: string | null;
}

export function NeuesPasswortModelFromJSON(json: any): NeuesPasswortModel {
    return NeuesPasswortModelFromJSONTyped(json, false);
}

export function NeuesPasswortModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NeuesPasswortModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'altesPasswort': !exists(json, 'altesPasswort') ? undefined : json['altesPasswort'],
        'neuesPasswort': !exists(json, 'neuesPasswort') ? undefined : json['neuesPasswort'],
    };
}

export function NeuesPasswortModelToJSON(value?: NeuesPasswortModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'altesPasswort': value.altesPasswort,
        'neuesPasswort': value.neuesPasswort,
    };
}


