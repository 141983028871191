/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Model: Arbeitstag eines Mitarbeiters
 * @export
 * @interface ArbeitsTagModel
 */
export interface ArbeitsTagModel {
    /**
     * Wochentag (Montag, Dienstag,...)
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    wochentag?: string | null;
    /**
     * Tag des Monats
     * @type {number}
     * @memberof ArbeitsTagModel
     */
    tag?: number;
    /**
     * Erster kommen Stempel
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    kommen1?: string | null;
    /**
     * Erster gehen Stempel
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    gehen1?: string | null;
    /**
     * Zweiter kommen Stempel
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    kommen2?: string | null;
    /**
     * Zweiter gehen Stempel
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    gehen2?: string | null;
    /**
     * Dritter kommen Stempel
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    kommen3?: string | null;
    /**
     * Dritter gehen Stempel
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    gehen3?: string | null;
    /**
     * Restlichen kommen / gehen Stempel
     * @type {Array<string>}
     * @memberof ArbeitsTagModel
     */
    kommenGehen?: Array<string> | null;
    /**
     * Sonstige Arbeitszeit
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    istSonstig?: string | null;
    /**
     * Getätigte Pause
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    pause?: string | null;
    /**
     * Getätigte Umziehzeit
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    umziehzeit?: string | null;
    /**
     * Getätigte Umziehzeit
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    montage?: string | null;
    /**
     * IST-Arbeitszeit
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    ist?: string | null;
    /**
     * SOLL-Arbeitszeit
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    soll?: string | null;
    /**
     * Differenz zu IST / Soll
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    plusMinus?: string | null;
    /**
     * 50% Mehrarbeitszeit
     * @type {number}
     * @memberof ArbeitsTagModel
     */
    saldo50?: number | null;
    /**
     * 100% Mehrarbeitszeit
     * @type {number}
     * @memberof ArbeitsTagModel
     */
    saldo100?: number | null;
    /**
     * Kennzeichnung des Tages (Urlaub, Frei, Krank,...)
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    code?: string | null;
    /**
     * Info zur Arbeit
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    info?: string | null;
    /**
     * Style der Ansicht
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    style?: string | null;
    /**
     * Icon für edit oder view
     * @type {string}
     * @memberof ArbeitsTagModel
     */
    symbol?: string | null;
}

export function ArbeitsTagModelFromJSON(json: any): ArbeitsTagModel {
    return ArbeitsTagModelFromJSONTyped(json, false);
}

export function ArbeitsTagModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArbeitsTagModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'wochentag': !exists(json, 'wochentag') ? undefined : json['wochentag'],
        'tag': !exists(json, 'tag') ? undefined : json['tag'],
        'kommen1': !exists(json, 'kommen1') ? undefined : json['kommen1'],
        'gehen1': !exists(json, 'gehen1') ? undefined : json['gehen1'],
        'kommen2': !exists(json, 'kommen2') ? undefined : json['kommen2'],
        'gehen2': !exists(json, 'gehen2') ? undefined : json['gehen2'],
        'kommen3': !exists(json, 'kommen3') ? undefined : json['kommen3'],
        'gehen3': !exists(json, 'gehen3') ? undefined : json['gehen3'],
        'kommenGehen': !exists(json, 'kommenGehen') ? undefined : json['kommenGehen'],
        'istSonstig': !exists(json, 'istSonstig') ? undefined : json['istSonstig'],
        'pause': !exists(json, 'pause') ? undefined : json['pause'],
        'umziehzeit': !exists(json, 'umziehzeit') ? undefined : json['umziehzeit'],
        'montage': !exists(json, 'montage') ? undefined : json['montage'],
        'ist': !exists(json, 'ist') ? undefined : json['ist'],
        'soll': !exists(json, 'soll') ? undefined : json['soll'],
        'plusMinus': !exists(json, 'plusMinus') ? undefined : json['plusMinus'],
        'saldo50': !exists(json, 'saldo50') ? undefined : json['saldo50'],
        'saldo100': !exists(json, 'saldo100') ? undefined : json['saldo100'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'info': !exists(json, 'info') ? undefined : json['info'],
        'style': !exists(json, 'style') ? undefined : json['style'],
        'symbol': !exists(json, 'symbol') ? undefined : json['symbol'],
    };
}

export function ArbeitsTagModelToJSON(value?: ArbeitsTagModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'wochentag': value.wochentag,
        'tag': value.tag,
        'kommen1': value.kommen1,
        'gehen1': value.gehen1,
        'kommen2': value.kommen2,
        'gehen2': value.gehen2,
        'kommen3': value.kommen3,
        'gehen3': value.gehen3,
        'kommenGehen': value.kommenGehen,
        'istSonstig': value.istSonstig,
        'pause': value.pause,
        'umziehzeit': value.umziehzeit,
        'montage': value.montage,
        'ist': value.ist,
        'soll': value.soll,
        'plusMinus': value.plusMinus,
        'saldo50': value.saldo50,
        'saldo100': value.saldo100,
        'code': value.code,
        'info': value.info,
        'style': value.style,
        'symbol': value.symbol,
    };
}


